import { logger } from '@magicschool/logger';
import type { PostgrestSingleResponse } from '@supabase/supabase-js';

export const MULTIPLE_ROWS_ON_SINGLE_ERROR = {
  error: {
    name: '',
    code: 'PGRST116',
    details: 'Results contain multiple rows, using "single()" requires 1 row',
    hint: '',
    message: 'JSON object requested, multiple (or no) rows returned',
  },
  data: null,
  count: null,
  status: 406,
  statusText: 'Not Acceptable',
};

export type SupabaseErrorType<T> = Extract<
  PostgrestSingleResponse<T>,
  {
    error: {
      message: string;
      details: string;
      hint: string;
      code: string;
    };
  }
>;

export class SupabaseError<T> extends Error {
  supabaseError: SupabaseErrorType<T>;
  constructor(response: SupabaseErrorType<T>) {
    super(response.error.message);
    this.supabaseError = response;
    const stack = new Error().stack;
    logger.error('SupabaseError', response, stack);
  }
}
