import {
  type CreateNewToolCustomization,
  type ToolCustomizationShareResponse,
  createNewToolCustomizationServerAction,
  editToolCustomizationServerAction,
  getToolCustomizationByShareCode,
  revalidateToolsServerAction,
  updateToolCustomizationSharing,
} from '@/app/(teacher)/tools/actions';
import { analyticsTrack } from '@/util/analytics';
import type { DashboardTool, ToolCustomization } from '@magicschool/business-logic/tools';
import { logger } from '@magicschool/logger';
import type { Category, ReturnTypeGetUserRecommendations } from '@magicschool/supabase/types';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import toast from 'react-hot-toast';
import type { IntlShape } from 'react-intl';
import type { ServerActionResponse } from '../server/actions/types';

export type ToolsStore = ToolsData & ToolsActions;
export type ToolsSortType = 'popular' | 'newest' | 'az' | 'za';

export type ToolsData = {
  selectedCategory: string;
  searchTerm: string;
  headerHeight: number;
  tools: DashboardTool[];
  recommendedTools: ReturnTypeGetUserRecommendations;
  categories: Category[];
  showStudent: boolean;
  searchResults: string[];
  sortType: ToolsSortType;
  loadingCustomTool: boolean;
  customToolCreationCallback: null | (() => void);
  loadingNewCustomization: boolean;
  deleteToolModalOpen: boolean;
  loadingShareTool: boolean;
  shareToolModalOpen: boolean;
  toolToShareResponse: ServerActionResponse<ToolCustomizationShareResponse> | null;
  copyToolName: string;
  shareDrawerOpen: boolean;
  shareDrawerLoading: boolean;
  toolCustomizationToShare: ToolCustomization | null;

  deleteCustomTool: (toolCustomizationId: string, intl: IntlShape, router: AppRouterInstance) => void;
  saveToolCustomization: (intl: IntlShape, router: AppRouterInstance) => void;
  openShareToolModal: (toolCustomizationId: string) => void;
  copyTool: (intl: IntlShape, router: AppRouterInstance) => void;
  closeShareToolModal: (router: AppRouterInstance) => void;
  openShareToolDrawer: (customization: ToolCustomization) => void;
  closeShareToolDrawer: () => void;
  resetShareToolDrawer: () => void;
  updateShareLinkAccessType: (intl: IntlShape, accessType: 'public' | 'private' | 'org') => void;
};

const defaultState = {
  selectedCategory: '',
  searchTerm: '',
  headerHeight: 0,
  tools: [],
  recommendedTools: [],
  categories: [],
  showStudent: false,
  searchResults: [],
  sortType: 'popular' as const,
  loadingCustomTool: false,
  customToolCreationCallback: null,
  loadingNewCustomization: false,
  deleteToolModalOpen: false,
  loadingShareTool: false,
  shareToolModalOpen: false,
  toolToShareResponse: null,
  copyToolName: '',
  shareDrawerOpen: false,
  shareDrawerLoading: false,
  toolCustomizationToShare: null,
};

export type ToolsActions = {
  setField: SetField<ToolsStore>;
};

export type CustomToolSharingAccessType = 'public' | 'private' | 'org';

export const createToolsPageStoreSlice = createStoreSlice(
  'ToolsStoreData',
  structuredClone(defaultState),
  ({ setField, set, get, getFull }) => ({
    setField,
    saveToolCustomization: async (intl, router) => {
      const {
        tempToolCustomization,
        toolToCustomize,
        validateFields,
        isNewTool,
        setField: setCustomToolField,
      } = getFull().ToolCustomizationStoreData;
      if (!tempToolCustomization || !toolToCustomize) return;

      const isEdit = !isNewTool && !!tempToolCustomization.id;

      // only show for initial tool creation
      if (!isEdit) {
        const customToolCreationCallback = async () => {
          await revalidateToolsServerAction();
          router.push('/tools');
          toast.success(intl.formatMessage({ id: 'new-custom-tool.success' }));
        };
        set({ loadingNewCustomization: true, customToolCreationCallback });
      }

      const fieldsValid = validateFields(toolToCustomize, tempToolCustomization);
      if (!fieldsValid) {
        setCustomToolField('invalidStateModalOpen')(true);
        return;
      }

      if (isEdit) {
        // update the existing tool
        const updated = await editToolCustomizationServerAction({
          id: tempToolCustomization.id,
          json_config: tempToolCustomization.json_config,
        });
        router.back();
        if (updated.error) {
          toast.error(intl.formatMessage({ id: 'new-custom-tool.error' }));
          throw new Error(updated.error.message);
        }
        toast.success(intl.formatMessage({ id: 'edit-custom-tool.success' }));
        return;
      }

      // create a new tool
      const newCustomization: ServerActionResponse<ToolCustomization> = await createNewToolCustomizationServerAction({
        tool_slug: tempToolCustomization.tool_slug,
        tool_uuid: tempToolCustomization.tool_uuid,
        json_config: tempToolCustomization.json_config,
        tool_application: 'individual',
      });

      if (newCustomization.error) {
        set({ loadingNewCustomization: false });
        router.push('/tools');
        toast.error(intl.formatMessage({ id: 'new-custom-tool.error' }));
        throw new Error(newCustomization.error.message);
      }
      set({ loadingNewCustomization: false });
    },
    deleteCustomTool: async (toolCustomizationId, intl, router) => {
      set({ loadingCustomTool: true });
      const response = await fetch(`/api/tool_customizations/${toolCustomizationId}`, { method: 'DELETE' });
      if (!response.ok) {
        toast.error(intl.formatMessage({ id: 'delete-custom-tool.error' }));
        return;
      }
      const tools = get().tools;
      set({
        tools: tools.filter((tool) => tool.toolCustomizationId !== toolCustomizationId),
        deleteToolModalOpen: false,
        loadingCustomTool: false,
      });
      await revalidateToolsServerAction();
      router.push('/tools');
      analyticsTrack('CustomToolChat:DeleteTool', {
        state: 'confirmed',
        location: 'ToolChat',
      });
      toast.success(intl.formatMessage({ id: 'delete-custom-tool.success' }));
    },
    openShareToolModal: async (toolCustomizationId) => {
      set({ toolToShareResponse: null, copyToolName: '' });
      set({ shareToolModalOpen: true, loadingShareTool: true });
      const fetchedTool = await getToolCustomizationByShareCode(toolCustomizationId);
      set({ toolToShareResponse: fetchedTool, loadingShareTool: false, copyToolName: fetchedTool.data?.title });
    },
    closeShareToolModal: (router) => {
      set({ shareToolModalOpen: false });
      router.push('/tools');
    },
    copyTool: async (intl, router) => {
      set({ loadingShareTool: true });
      const { toolToShareResponse, copyToolName, closeShareToolModal } = get();
      const toolData = toolToShareResponse?.data;
      if (!toolData) {
        return;
      }
      const newToolCustomization: CreateNewToolCustomization = {
        tool_slug: toolData.toolSlug,
        tool_uuid: toolData.toolId,
        json_config: { ...toolData.toolCustomization.json_config, tool_title: copyToolName },
        tool_application: 'individual',
      };
      const response = await createNewToolCustomizationServerAction(newToolCustomization);
      if (response.error) {
        toast.error(intl.formatMessage({ id: 'share-tool.error' }));
        closeShareToolModal(router);
        return;
      }
      // // Actualy dont need to do this because the page refreshes and fetches the new tool data...
      // const { toolCustomization, ...dashboardTool } = toolData;
      // set({ tools: [{ ...dashboardTool, title: copyToolName, toolCustomizationId: response.data?.id || null }, ...tools] });
      toast.success(intl.formatMessage({ id: 'share-tool.success' }));
      await revalidateToolsServerAction();
      closeShareToolModal(router);
    },
    openShareToolDrawer: (customization) => {
      get().resetShareToolDrawer();
      set({ shareDrawerOpen: true, toolCustomizationToShare: customization, shareDrawerLoading: false });
    },
    closeShareToolDrawer: () => {
      set({ shareDrawerOpen: false });
    },
    resetShareToolDrawer: () => {
      set({ toolCustomizationToShare: null, shareDrawerLoading: false });
    },
    updateShareLinkAccessType: async (intl, accessType: 'public' | 'private' | 'org') => {
      const { toolCustomizationToShare, closeShareToolDrawer } = get();
      if (!toolCustomizationToShare?.id) return;
      set({ shareDrawerLoading: true });
      analyticsTrack('CustomEducatorTool:UpdateShareLinkAccessType', {
        location: 'CustomTool',
        accessType,
        toolCustomizationId: toolCustomizationToShare.id,
      });

      const result = await updateToolCustomizationSharing(toolCustomizationToShare.id, accessType);
      if (result.error || !result.data) {
        logger.error(result?.error?.message || `Error in updating sharing link for tool_customization.id: ${toolCustomizationToShare.id}`, {
          error: result.error,
        });
        toast.error(intl.formatMessage({ id: 'update-share-tool.error' }));
        closeShareToolDrawer();
      }

      set({ shareDrawerLoading: false, toolCustomizationToShare: result.data });
    },
  }),
);
