import { RoomTeacherRole } from '@magicschool/supabase/types';
import type { Room } from '@magicschool/supabase/types';

export const roomSharingQueryParam = 'room-sharing-id';

export const defaultRoom: Room = {
  created_at: '',
  max_students: 250,
  require_login: false,
  id: '',
  join_code: '',
  name: '',
  grade_level: 'pre-k',
  state: 'active' as const,
  state_changed_at: '',
  status: 'active',
  teacher_join_code: '',
  creator_org_id: null,
  avatar_color: '',
  sharing_access_type: 'private',
  sharing_code: '',
  updated_at: '',
  edited_at: '',
};

export const defaultRoomWithTeachers = {
  ...defaultRoom,
  room_teachers: [],
};

export const roomTeacherRoleIntlMap: Record<RoomTeacherRole, string> = {
  [RoomTeacherRole.administrator]: 'role.administrator',
  [RoomTeacherRole.creator]: 'role.creator',
  [RoomTeacherRole.coteacher]: 'role.coteacher',
  [RoomTeacherRole.pending]: 'coteacher.pending',
  [RoomTeacherRole.rejected]: 'coteacher.rejected',
};
