import { analyticsTrack } from '@/util/analytics';
import type { ImmutableList } from '@/util/immutable';
import { type SetField, createStoreSlice } from 'features/store/zustand';

export type NudgeStore = {
  completedNudges: ImmutableList<string>;
  setField: SetField<NudgeStore>;
  completeNudge: (nudgeId: string) => void;
};

type InitialProps = Pick<NudgeStore, 'completedNudges'>;

export const createNudgeStoreSlice = ({ completedNudges }: InitialProps) =>
  createStoreSlice('NudgeStoreData', { completedNudges }, ({ set, get, setField }) => ({
    setField,
    completeNudge: (nudgeId) => {
      const { completedNudges } = get();
      const updatedCompletedNudges = completedNudges.push(nudgeId).toSet().toList();

      analyticsTrack('Nudge:Clicked', { nudgeId });

      fetch(`/api/nudges`, {
        method: 'POST',
        body: JSON.stringify({ completedNudges: updatedCompletedNudges }),
        responseErrorHandlers: {
          unauthorized: () => ({ shortCircuit: true }),
          notFound: () => ({ shortCircuit: true }),
          forbidden: () => ({ shortCircuit: true }),
          badRequest: () => ({ shortCircuit: true }),
          conflict: () => ({ shortCircuit: true }),
          unknown: () => ({ shortCircuit: true }),
        },
      });

      set({ completedNudges: updatedCompletedNudges });
    },
  }));
