import type { PlanFeatures } from './types';

export const plusFeatures: PlanFeatures = {
  fullHistory: true,
  rainaCustomizations: true,
  chatExports: true,
  magicStudentEnabled: true,
};

export const partnerFeatures: PlanFeatures = {
  fullHistory: true,
  rainaCustomizations: true,
  chatExports: true,
  magicStudentEnabled: true,
};
