import { analyticsTrack } from '@/util/analytics';
import { ImmutableList } from '@/util/immutable';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import type { IntlShape } from 'react-intl';
import { steps } from './Steps';

export type ProfileBuilderStore = {
  profileBuilderOpen: boolean;
  bestPracticesModalOpen: boolean;
  currentStepNumber: number;
  isNewUser: boolean;
  selectedRole: string;
  selectedGrades: ImmutableList<string>;
  selectedSubjects: ImmutableList<string>;
  customSubject: string;
  fullName: string;
  studentFriendlyName: string;
  saving: boolean;
  setField: SetField<ProfileBuilderStore>;
  load: () => void;
  next: ({ intl, refresh }: { intl: IntlShape; refresh?: () => void }) => void;
  back: () => void;
};

const defaultState = {
  profileBuilderOpen: false,
  bestPracticesModalOpen: false,
  currentStepNumber: 0,
  isNewUser: true,
  selectedRole: '',
  selectedGrades: ImmutableList<string>(),
  selectedSubjects: ImmutableList<string>(),
  customSubject: '',
  fullName: '',
  studentFriendlyName: '',
  saving: false,
};

export const createProfileBuilderStoreSlice = createStoreSlice(
  'ProfileBuilderStoreData',
  defaultState,
  ({ set, get, getFull, setField }) => ({
    setField,
    load: () => {
      const userData = getFull().AuthStoreData.userData;
      set({
        isNewUser: !userData.profile_status || userData.profile_status === 'new',
        profileBuilderOpen: userData.profile_status !== 'completed',
        selectedRole: userData.role || '',
        selectedGrades: ImmutableList(userData.grades || []),
        selectedSubjects: ImmutableList(userData.subjects || []),
        customSubject: userData.custom_subject || '',
        fullName: userData.name || '',
        studentFriendlyName: userData.student_friendly_name || '',
        saving: false,
      });
    },
    next: async ({ intl, refresh }) => {
      const { currentStepNumber } = get();
      const user = getFull().AuthStoreData.userData;
      const updateProfile = getFull().AuthStoreData.updateProfile;

      const stepId = steps[currentStepNumber].id;

      const advance = () => set({ currentStepNumber: Math.min(currentStepNumber + 1, steps.length - 1), saving: false });

      set({ saving: true });

      switch (stepId) {
        case 'welcome': {
          analyticsTrack('ProfileBuilder:BuildProfileClicked');
          advance();
          return;
        }
        case 'loader': {
          advance();
          return;
        }
        case 'role': {
          const { selectedRole } = get();

          analyticsTrack('ProfileBuilder:RoleSelected', { role: selectedRole });

          const updatedUser = { ...user, role: selectedRole };
          await updateProfile({ user: updatedUser, intl, inProfileBuilder: true });
          advance();

          return;
        }
        case 'grades': {
          const { selectedGrades } = get();

          analyticsTrack('ProfileBuilder:GradeSelected', { grades: selectedGrades.toArray() });
          const updatedUser = { ...user, grades: selectedGrades.toArray() };
          await updateProfile({ user: updatedUser, intl, inProfileBuilder: true });
          advance();

          return;
        }
        case 'subjects': {
          const { selectedSubjects, customSubject } = get();

          analyticsTrack('ProfileBuilder:SubjectSelect', { subjects: selectedSubjects.toArray(), customSubject });
          const updatedUser = { ...user, subjects: selectedSubjects.toArray(), custom_subject: customSubject };
          await updateProfile({ user: updatedUser, intl, inProfileBuilder: true });
          advance();

          return;
        }
        case 'name': {
          const { fullName, studentFriendlyName } = get();
          analyticsTrack('ProfileBuilder:NameInput', { fullName: Boolean(fullName), studentFriendlyName: Boolean(studentFriendlyName) });

          const updatedUser = { ...user, name: fullName, student_friendly_name: studentFriendlyName, profile_status: 'completed' as const };
          await updateProfile({ user: updatedUser, intl, inProfileBuilder: true });
          // Refresh the page to show the correct recommended tools
          if (refresh) refresh();
          set({ profileBuilderOpen: false, bestPracticesModalOpen: true });

          return;
        }
        default:
          return;
      }
    },
    back: () => {
      const { currentStepNumber } = get();
      analyticsTrack('ProfileBuilder:BackClicked', { page: steps[currentStepNumber].id });
      set({ currentStepNumber: Math.max(currentStepNumber - 1, 0) });
    },
  }),
);
