import * as amplitude from '@amplitude/analytics-browser';
import type { MagicSchoolUser } from '@magicschool/supabase/user';
import { sendGAEvent } from '@next/third-parties/google';
import isNil from 'lodash-es/isNil';
import omitBy from 'lodash-es/omitBy';
import { MILLISECONDS_IN_DAY } from './date';

const AMPLITUDE_CONFIG: amplitude.Types.BrowserOptions = {
  autocapture: {
    attribution: true,
    sessions: true,
    pageViews: false,
    formInteractions: false,
    fileDownloads: false,
    elementInteractions: false,
  },
  defaultTracking: {
    sessions: true,
    pageViews: false,
    formInteractions: false,
    fileDownloads: false,
    attribution: {
      resetSessionOnNewCampaign: true,
    },
  },
};

export const initAmplitude = () => {
  // by default we don't run on our local environments
  if (process.env.NEXT_PUBLIC_USE_AMPLITUDE !== 'true') return;

  amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY ?? '', AMPLITUDE_CONFIG);
};

export const setAmplitudeUserId = (userId: string | null) => {
  if (userId) {
    amplitude.setUserId(userId);
  } else {
    amplitude.reset();
  }
};

// Cache user traits traits to send on every event
let userTraitsCache = {};
export const setUserTraits = (user: MagicSchoolUser | null) => {
  if (!user) {
    userTraitsCache = {};
    return;
  }

  const identify = new amplitude.Identify();
  for (const [key, value] of Object.entries(user)) {
    if (value !== undefined && value !== null) {
      if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
        identify.set(key, value);
      } else if (Array.isArray(value)) {
        identify.set(key, value);
      } else if (typeof value === 'object') {
        // amplitude doesn't like nulls!
        const obj = omitBy(value, isNil) as amplitude.Types.ValidPropertyType;
        identify.set(key, obj);
      }
    }
  }
  amplitude.identify(identify);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'setUserTraits',
    ...user,
  });

  const isNew = !user.created_at ? false : new Date(user.created_at) > new Date(new Date().getTime() - 14 * MILLISECONDS_IN_DAY);

  userTraitsCache = {
    id: user.id,
    email: user.email,
    authentication_provider: user.authProvider,
    created_at: user.created_at,
    confirmed_at: user.confirmed_at,
    updated_at: user.updated_at,
    email_confirmed_at: user.email_confirmed_at,
    name: user.name,
    role: user.role,
    subjects: user.subjects,
    grades: user.grades,
    school: user.school,
    district: user.district,
    city: user.city,
    state: user.state,
    country: user.country,
    plan: user.plan,
    org: user.org_id,
    status: user.status,
    user_role: user.user_role,
    profile_status: user.profile_status,
    best_practices_completed: user.best_practices_completed,
    custom_subject: user.custom_subject,
    student_friendly_name: user.student_friendly_name,
    nudge_test_serial_id: isNew ? user.serial_id % 2 : -1,
  };
};

export const analyticsTrack = (eventType: string, eventProperties?: Record<string, unknown>) => {
  const properties = {
    ...eventProperties,
    user: { ...userTraitsCache },
  };
  amplitude.track(eventType, properties);
  sendGAEvent(eventType, properties);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: eventType, ...properties });
};

export const analyticsHandleUserLogout = () => {
  amplitude.reset();
  amplitude.track('User Logout', { isAnonymous: true });
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: 'User Logout', isAnonymous: true });
};
