import MuiButton, { type ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { type Theme, alpha, styled, useTheme } from '@mui/material/styles';
import { radius } from '../../constants/radius';
import { space } from '../../constants/space';
export type ButtonShape = 'rect' | 'pill';
export type ButtonOrdinal = 'primary' | 'secondary' | 'tertiary' | 'error' | 'success';
export type ButtonSize = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
export type ButtonColors = {
  main: string;
  dark: string;
};
export type ButtonProps = MuiButtonProps & {
  /**
   * The visual style or "ordinal" of the button.
   * @default 'primary'
   */
  ordinal?: ButtonOrdinal;

  /**
   * The size of the button.
   * @default 'medium'
   */
  size?: ButtonSize;

  /**
   * The shape of the button, either 'rect' for rectangular or 'pill' for rounded.
   * @default 'rect'
   */
  shape?: ButtonShape;
};
const BaseButton = styled(MuiButton, {
  shouldForwardProp: prop => prop !== 'shape' && prop !== 'ordinal' && prop !== 'colors'
})<ButtonProps & {
  colors: ButtonColors;
}>(({
  theme,
  shape,
  colors,
  disabled
}) => ({
  borderRadius: shape === 'pill' ? radius.round : radius[0.5],
  '&.MuiButton-sizeXsmall': {
    fontSize: '.75rem',
    padding: space(0.5, 1)
  },
  '&.MuiButton-sizeXlarge': {
    fontSize: '1rem',
    padding: space(2, 5)
  },
  '&.MuiButton-contained': {
    backgroundColor: disabled ? theme.palette.action.disabledBackground : colors.main,
    '&:hover': {
      backgroundColor: colors.dark
    }
  },
  '&.MuiButton-outlined': {
    backgroundColor: 'transparent',
    border: `1px solid ${disabled ? theme.palette.action.disabled : alpha(colors.main, 0.5)}`,
    color: colors.main,
    '&:hover': {
      backgroundColor: alpha(colors.main, 0.04),
      border: `1px solid ${disabled ? theme.palette.action.disabled : colors.main}`
    }
  },
  '&.MuiButton-text, &.MuiButton-outlined': {
    color: disabled ? theme.palette.action.disabled : colors.main,
    '&:hover': {
      backgroundColor: alpha(colors.main, 0.04)
    }
  }
}));
const getColors = (theme: Theme, ordinal?: ButtonOrdinal) => {
  switch (ordinal) {
    case 'secondary':
      return {
        main: theme.palette.secondary.main,
        dark: theme.palette.secondary.dark
      };
    case 'tertiary':
      return {
        main: theme.palette.tertiary.main,
        dark: theme.palette.tertiary.dark
      };
    case 'error':
      return {
        main: theme.palette.error.main,
        dark: theme.palette.error.dark
      };
    case 'success':
      return {
        main: theme.palette.success.main,
        dark: theme.palette.success.dark
      };
    case 'primary':
    default:
      return {
        main: theme.palette.primary.main,
        dark: theme.palette.primary.dark
      };
  }
};

/**
 * A customizable button component with support for different sizes, shapes, and color schemes.
 */
export const Button = (props: ButtonProps) => {
  const theme = useTheme();
  const colors = getColors(theme, props.ordinal);
  return <BaseButton colors={colors} {...props} data-sentry-element="BaseButton" data-sentry-component="Button" data-sentry-source-file="Button.tsx">
      {props.children}
    </BaseButton>;
};