import MuiGrid, { type GridProps as MuiGridProps } from '@mui/material/Grid';
import { forwardRef } from 'react';
export interface GridProps extends MuiGridProps {
  'aria-label'?: string;
  'data-test-id'?: string;
  ref?: React.Ref<HTMLDivElement>;
}
export const Grid = forwardRef<HTMLDivElement, GridProps>(({
  children,
  ...rest
}, ref) => {
  return <MuiGrid ref={ref} {...rest}>
      {children}
    </MuiGrid>;
});