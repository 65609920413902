import { createNewToolCustomizationServerAction } from '@/app/(teacher)/tools/actions';
import { storage } from '@/features/storage';
import type { Voice } from '@/features/tts/types';
import type { FieldVisibility, InputsType } from '@magicschool/business-logic/tools';
import type { SafeToolInfo } from '@magicschool/business-logic/tools';
import type { ToolApplicationTypeType } from '@magicschool/supabase/types';
import type { FormBuilderValues } from 'components/FormElements/FormBuilder';
import type { FormElementProps } from 'components/FormElements/types';
import { type ToolCustomization, emptyToolCustomization, generateValidToolCustomization } from 'features/rooms/types';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import isEqual from 'lodash-es/isEqual';
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import toast from 'react-hot-toast';
import type { IntlShape } from 'react-intl';
import { ImmutableList, ImmutableMap } from 'util/immutable';
import { generateTestText } from './generateTestText';

export type CustomizingStep = 'edit' | 'customInstructions' | 'test';

export type SetToolCustomizationVisibility = (name: string, visibility: FieldVisibility) => void;

export type ToolCustomizationStore = {
  toolToCustomize: SafeToolInfo | null;
  existingToolCustomization: ToolCustomization | null;
  tempToolCustomization: ToolCustomization | null;
  templateNameModalOpen: boolean;
  templateName: string;
  invalidStateModalOpen: boolean;
  invalidFields: ImmutableList<FormElementProps>;
  toolCustomizationTemplatesMap: ImmutableMap<string, ImmutableList<ToolCustomization>>;
  cancelModalOpen: boolean;
  searchTerm: string;
  selectedCategory: string;
  selectedGradeLevel: string;
  customizingStep: CustomizingStep;
  toolApplication: ToolApplicationTypeType | null;
  audioElement: HTMLAudioElement | null;
  isNewTool: boolean;
  isLoadingAudio: boolean;

  startToolCustomization: (tool: SafeToolInfo, toolApplication: ToolApplicationTypeType, existingCustomization?: ToolCustomization) => void;
  setToolCustomizationVisibility: SetToolCustomizationVisibility;
  updateCustomizedTool: (values: FormBuilderValues) => void;
  saveCustomToolTitle: (title: string) => void;
  saveCustomToolDescription: (description: string) => void;
  setCustomToolVoice: (voice: Voice) => void;
  testVoice: (intl: IntlShape) => void;
  saveCustomInstructions: (value: InputsType[string]) => void;
  cancelCustomizeTool: (router: AppRouterInstance) => void;
  deleteToolTemplate: (tc: ToolCustomization) => Promise<void>;
  addToolTemplate: () => Promise<void>;
  selectToolTemplate: (selected: ToolCustomization) => void;
  openTemplateNameModal: () => void;
  resetCustomization: () => void;
  discardToolChanges: (router: AppRouterInstance) => void;
  validateFields: (tool: SafeToolInfo, customization: ToolCustomization) => boolean;
  load: (toolDetails: SafeToolInfo[], toolCustomization: ToolCustomization | null) => void;
  setField: SetField<ToolCustomizationStore>;
};

const defaultState = {
  searchTerm: '',
  selectedCategory: '',
  toolToCustomize: null,
  existingToolCustomization: null,
  tempToolCustomization: null,
  templateNameModalOpen: false,
  templateName: '',
  toolCustomizationTemplatesMap: ImmutableMap<string, ImmutableList<ToolCustomization>>(),
  invalidStateModalOpen: false,
  invalidFields: ImmutableList<FormElementProps>(),
  cancelModalOpen: false,
  selectedGradeLevel: 'pre-k',
  customizingStep: 'edit' as CustomizingStep,
  toolApplication: null,
  audioElement: null,
  isNewTool: true,
  isLoadingAudio: false,
};

export const createToolCustomizationStoreSlice = createStoreSlice(
  'ToolCustomizationStoreData',
  { ...defaultState },
  ({ set, get, getFull, setField }) => ({
    setField,
    startToolCustomization: (td, toolApplication, existingCustomization) => {
      const { toolCustomizationTemplatesMap, selectedGradeLevel, ...resetState } = { ...defaultState };
      set({ ...resetState });
      getFull().ChatStoreData.clearChatStore();
      const roomGradeLevel = getFull().RoomSettingsStoreData.selectedGradeLevel;

      const initialGradeLevel = roomGradeLevel || storage.getItem(`last-grade-level-selected`) || 'pre-k';
      const tempToolCustomization = existingCustomization
        ? generateValidToolCustomization(td.fields, existingCustomization)
        : emptyToolCustomization(td, initialGradeLevel, toolApplication);
      set({
        selectedGradeLevel: initialGradeLevel,
        toolToCustomize: td,
        tempToolCustomization,
        existingToolCustomization: tempToolCustomization,
        toolApplication,
        isNewTool: !existingCustomization,
      });
    },
    saveCustomToolTitle: (title) => {
      const { toolToCustomize, tempToolCustomization } = get();
      if (!toolToCustomize || !tempToolCustomization) return;

      const clone = structuredClone(tempToolCustomization);
      clone.json_config.tool_title = title;
      set({ tempToolCustomization: clone });
    },
    saveCustomToolDescription: (description) => {
      const { toolToCustomize, tempToolCustomization } = get();
      if (!toolToCustomize || !tempToolCustomization) return;

      const clone = structuredClone(tempToolCustomization);
      clone.json_config.tool_description = description;
      set({ tempToolCustomization: clone });
    },
    setCustomToolVoice: (voice) => {
      const { toolToCustomize, tempToolCustomization } = get();
      if (!toolToCustomize || !tempToolCustomization) return;

      const clone = structuredClone(tempToolCustomization);
      clone.json_config.voice = voice;
      set({ tempToolCustomization: clone });
    },
    saveCustomInstructions: (value) => {
      const { toolToCustomize, tempToolCustomization } = get();
      if (!toolToCustomize || !tempToolCustomization) return;

      const clone = structuredClone(tempToolCustomization);
      clone.json_config.customInstructions = value;
      set({ tempToolCustomization: clone });
    },
    testVoice: async (intl) => {
      set({ isLoadingAudio: true });
      const { tempToolCustomization, audioElement } = get();

      if (audioElement) {
        audioElement.pause();
        set({ audioElement: null });
      }

      const res = await fetch('/api/speech/tts', {
        method: 'POST',
        body: JSON.stringify({
          text: generateTestText(),
          voice: tempToolCustomization?.json_config.voice || 'nova',
        }),
        responseErrorHandlers: {
          unknown: () => ({ shortCircuit: true }),
        },
      });
      if (!res.ok) {
        set({ isLoadingAudio: false });
        toast.error(intl.formatMessage({ id: 'voice-test.error' }));
        throw Error('Failed to fetch audio');
      }

      // play streamed audio file
      const mediaSource = new MediaSource();
      const audio = new Audio();
      audio.src = URL.createObjectURL(mediaSource);
      set({ audioElement: audio });

      // Wait for media source to be ready
      mediaSource.addEventListener('sourceopen', async () => {
        const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');

        // Read from body stream and append to source buffer
        const reader = res.body?.getReader();
        if (!reader) return;

        audio.play();
        let streamingComplete = false;
        set({ isLoadingAudio: false });

        while (!streamingComplete) {
          const { value, done } = await reader.read();

          if (done) {
            streamingComplete = true;
            break;
          }

          await new Promise((resolve, _) => {
            sourceBuffer.appendBuffer(value);
            sourceBuffer.onupdateend = () => {
              resolve(true);
            };
          });
        }
      });
    },
    setToolCustomizationVisibility: (name, value) => {
      const { tempToolCustomization, toolToCustomize, validateFields } = get();
      if (!toolToCustomize || !tempToolCustomization) return;
      const clone = structuredClone(tempToolCustomization);
      clone.json_config.inputs[name].visibility = value;
      validateFields(toolToCustomize, clone);
      set({ tempToolCustomization: clone });
    },
    updateCustomizedTool: (values) => {
      const { tempToolCustomization, toolToCustomize, validateFields } = get();
      if (!toolToCustomize || !tempToolCustomization) return;
      const clone = structuredClone(tempToolCustomization);
      const fieldNames = Object.keys(clone.json_config.inputs);
      for (const name of fieldNames) {
        clone.json_config.inputs[name].value = values[name];
      }
      validateFields(toolToCustomize, clone);
      set({ tempToolCustomization: clone });
    },
    cancelCustomizeTool: (router) => {
      const { tempToolCustomization, toolToCustomize, discardToolChanges, existingToolCustomization } = get();
      if (!tempToolCustomization || !toolToCustomize) return;

      const dirty = !isEqual(existingToolCustomization?.json_config, tempToolCustomization.json_config);
      if (dirty) {
        set({ cancelModalOpen: true });
      } else {
        discardToolChanges(router);
      }
    },
    addToolTemplate: async () => {
      const { tempToolCustomization, templateName, toolToCustomize, validateFields } = get();
      if (!tempToolCustomization || !toolToCustomize) return;

      const fieldsValid = validateFields(toolToCustomize, tempToolCustomization);
      if (!fieldsValid) {
        set({ invalidStateModalOpen: true });
        return;
      }

      const newCustomization = await createNewToolCustomizationServerAction({
        name: templateName,
        tool_slug: tempToolCustomization.tool_slug,
        tool_uuid: tempToolCustomization.tool_uuid,
        json_config: tempToolCustomization.json_config,
        tool_application: 'room',
      });
      const data = newCustomization.data;

      set((s) => ({
        templateNameModalOpen: false,
        templateName: '',
        toolCustomizationTemplatesMap: data
          ? s.toolCustomizationTemplatesMap.update(tempToolCustomization.tool_uuid, ImmutableList(), (tcs) => tcs.push(data))
          : s.toolCustomizationTemplatesMap,
      }));
    },
    deleteToolTemplate: async (tc) => {
      const response = await fetch(`/api/tool_customizations/${tc.id}`, { method: 'DELETE' });
      if (!response.ok) return;

      set((s) => ({
        toolCustomizationTemplatesMap: s.toolCustomizationTemplatesMap.update(tc.tool_uuid, ImmutableList(), (tcs) =>
          tcs.filter((t) => t.id !== tc.id),
        ),
      }));
    },
    selectToolTemplate: (selected) => {
      const { tempToolCustomization } = get();
      if (!tempToolCustomization) return;
      set({ tempToolCustomization: { ...tempToolCustomization, json_config: { ...selected.json_config } } });
    },
    openTemplateNameModal: () => {
      const { tempToolCustomization, toolToCustomize, validateFields } = get();
      if (!tempToolCustomization || !toolToCustomize) return;

      // We don't want to allow users to save invalid templates
      const fieldsValid = validateFields(toolToCustomize, tempToolCustomization);
      const key = fieldsValid ? 'templateNameModalOpen' : 'invalidStateModalOpen';
      set({ [key]: true });
    },
    discardToolChanges: (router) => {
      const setField = getFull().RoomSettingsStoreData.setField;
      const { isNewTool, toolApplication } = get();
      const { toolCustomizationTemplatesMap, selectedGradeLevel, ...resetState } = { ...defaultState };
      if (!isNewTool && toolApplication !== 'room') {
        router.back();
      }
      set(resetState);
      setField('roomToolToCustomize')(null);
    },
    resetCustomization: () => {
      const { toolToCustomize, selectedGradeLevel } = get();
      if (!toolToCustomize) return;
      set({ tempToolCustomization: emptyToolCustomization(toolToCustomize, selectedGradeLevel), invalidStateModalOpen: false });
    },
    validateFields: (tool, customization) => {
      const invalidFields = ImmutableList(tool.fields).filter((f) => {
        const overrides = customization.json_config.inputs[f.name];

        // If the field is required and hidden and has no value, it's invalid because the
        // students would not be able to see it or fill it out
        return Boolean(overrides.visibility === 'hidden' && f.required && !overrides.value);
      });

      set({ invalidFields });

      return invalidFields.isEmpty();
    },
    load: (toolDetails, toolCustomization) => {
      const { startToolCustomization } = get();
      const { toolCustomizationTemplatesMap, selectedGradeLevel, ...resetState } = { ...defaultState };
      set({ ...resetState });

      const tool = toolDetails.find((td) => td.tool.id === toolCustomization?.tool_uuid);
      if (tool && toolCustomization) {
        startToolCustomization(tool, 'individual', toolCustomization);
      }
    },
  }),
);
