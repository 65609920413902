'use client';

import { type FeatureFlags, getFeatureFlags } from '@magicschool/business-logic';
import { usePathname } from 'next/navigation';
import { useAuth } from './useAuth';
import { useSiteConfig } from './useSiteConfig';

function useFeatureFlags(): FeatureFlags {
  const { userData, planId, userAttributes, orgAttributes } = useAuth();
  const { featureFlags: siteFeatures } = useSiteConfig();
  const pathname = usePathname();
  const isStudent = pathname?.includes('/s/');

  return getFeatureFlags({
    user: Object.keys(userData).length > 0 ? userData : null,
    planId,
    orgAttributes,
    userAttributes,
    siteFeatures,
    isStudent,
  });
}

export default useFeatureFlags;
