import lunr from 'lunr';
import type { ModerationCategory } from './constants';
import type { LunrFunction } from './types';

// Can't get the d.ts files to work, so we'll just import them and cast them to the proper type
const lunrSpanish = require('lunr-languages/lunr.es') as LunrFunction;
const lunrMultiLanguage = require('lunr-languages/lunr.multi') as LunrFunction;
const lunrStemmer = require('lunr-languages/lunr.stemmer.support') as LunrFunction;

// Set up lunr.js with the necessary language support
lunrStemmer(lunr);
lunrMultiLanguage(lunr);
lunrSpanish(lunr);

export const calculateScale = (categoryScores: Record<ModerationCategory, number>, thresholds: Record<ModerationCategory, number>) => {
  const scaledResults = {} as Record<ModerationCategory, number>;

  for (const category in categoryScores) {
    const score = categoryScores[category as ModerationCategory];
    const threshold = thresholds[category as ModerationCategory];

    // Calculate the ratio
    const ratio = score / threshold;

    // Scale the ratio to a 1-10 scale
    const scaledScore = Math.min(Math.max(ratio * 10, 1), 10);

    // Store the scaled result
    scaledResults[category as ModerationCategory] = scaledScore;
  }

  return scaledResults;
};

interface LunrMetadata {
  text: {
    position: number[][];
  };
}

export function detectFlaggedWords(text: string, flaggedKeywords: string[]): string[] {
  text = text.replace(/["/]+/g, ' ');

  // Index this text using lunr.js
  // This allows us to use stemming to find similar words (ie. hate in our filter matches hated, hating, etc)
  const indexedText = lunr(function () {
    // Use multiple languages
    this.use(lunr.multiLanguage('en', 'es'));

    this.metadataWhitelist = ['position'];

    this.field('text');
    this.add({ text });
  });

  // Search the indexed text for any words that match our filter list
  const searchResults = indexedText.search(flaggedKeywords.join(' '));
  if (searchResults.length === 0) return [];

  // For each result, pull out the word(s) that was matched
  const metadata = searchResults[0].matchData.metadata as Record<string, LunrMetadata>;
  const wordRoots = Object.keys(metadata);
  const results: string[] = [];
  for (const root of wordRoots) {
    for (const position of metadata[root].text.position) {
      results.push(text.slice(position[0], position[0] + position[1]));
    }
  }

  return results;
}
