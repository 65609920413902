import type { SurveyWrapper } from '@/app/api/survey/route';
import { logger } from '@magicschool/logger';
import type { SurveyConfig, User } from '@magicschool/supabase/types';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import { setSurveyAsDismissed } from './actions';
import type { SurveyShape } from './types';

export type SurveyStore = {
  key: string | null;
  setField: SetField<SurveyStore>;
  open: boolean;
  surveyConfig: SurveyConfig | null;
  handleClose: (userClosed: boolean, user?: User) => Promise<void>;
  load: (key: string) => Promise<void>;
  submit: (response: { [key: string]: any }) => void;
  submitResult: 'success' | 'error' | null;
};

const defaultState = {
  key: null,
  open: false,
  surveyConfig: null,
  submitResult: null,
};

export const createSurveyStoreSlice = createStoreSlice('SurveyStoreData', defaultState, ({ set, get, setField }) => ({
  setField,
  load: async (key) => {
    set({ ...defaultState, key });
    await fetch<SurveyWrapper>(`/api/survey?key=${key}`, {
      cache: 'no-store',
      next: { revalidate: 0 },
      onSuccess: async ({ response }) => {
        const surveyData = await response.json();
        logger.debug('surveyData', surveyData);
        // if we have a survey config and no user response, we should show the survey
        if (surveyData?.surveyConfig && !surveyData.userResponse) {
          // If the user has not answered this survey or and the survey has dismissed it but not more than the max dismissible count
          const config = surveyData.surveyConfig.config as unknown as SurveyShape;
          const dismissibleCount = config?.magicSchool?.dismissibleCount ?? -1;
          logger.debug('config', config);
          logger.debug('dismissibleCount', dismissibleCount);
          logger.debug('surveyUserAttribute', surveyData.surveyUserAttribute);
          logger.debug('userSurveyAttribute', surveyData.userSurveyAttribute);
          // if the survey has a dismissible count and the user has not dismissed it more than the max dismissible count
          if (
            dismissibleCount > 0 &&
            // even though <= makes sense, the count starts at 0 so this is essentially 1 indexing the check instead of 0
            (!surveyData.userSurveyAttribute || surveyData.userSurveyAttribute.dismissedCount < dismissibleCount)
          ) {
            set({ surveyConfig: surveyData.surveyConfig, open: true });
          }
        }
      },
      responseErrorHandlers: {
        unauthorized: () => ({ shortCircuit: true }),
        notFound: () => ({ shortCircuit: true }),
        forbidden: () => ({ shortCircuit: true }),
        badRequest: () => ({ shortCircuit: true }),
        conflict: () => ({ shortCircuit: true }),
        unknown: () => ({ shortCircuit: true }),
      },
    });
  },
  submit: async (response: { [key: string]: any }) => {
    const { handleClose, key } = get();
    const data = JSON.stringify({ surveyId: key, userResponse: response });
    await fetch(`/api/survey`, {
      method: 'POST',
      body: data,
      onSuccess: () => {
        set({ submitResult: 'success' });
      },
      responseErrorHandlers: {
        unauthorized: () => ({ shortCircuit: true }),
        notFound: () => ({ shortCircuit: true }),
        forbidden: () => ({ shortCircuit: true }),
        badRequest: () => ({ shortCircuit: true }),
        conflict: () => ({ shortCircuit: true }),
        unknown: () => {
          set({ submitResult: 'error' });
          handleClose(false);
          logger.error('Error submitting survey', data);
        },
      },
    });
  },
  handleClose: async (userClosed: boolean, user?: User) => {
    const { key } = get();
    set({ open: false });
    // this can take a second - just close the modal first
    if (userClosed && user && key) {
      // we call the same function to close the modal if they submit the survey or if they close the modal without submitting.
      // if it was a user initiated close, track it as such.
      await setSurveyAsDismissed(key);
    }
  },
}));
