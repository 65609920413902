import type { GeneratePromptAssistantResponse } from '@/app/api/generations/generatePromptAssistant/route';

import { type SetField, createStoreSlice } from 'features/store/zustand';

export type UserMessage = { userText: string; messageRole: 'user' };
export type AssistantMessage = { originalPrompt: string; improvedPrompt: string; promptImprovements: string[]; messageRole: 'assistant' };
export type PromptAssistantStore = {
  promptAssistantDrawerOpen: boolean;
  slug: string;
  loading: boolean;
  fieldName: string;
  messages: (UserMessage | AssistantMessage)[];
  loadingImprovements: boolean;
  setField: SetField<PromptAssistantStore>;
  load: ({
    fieldName,
    originalPrompt,
    slug,
  }: { fieldName: string; originalPrompt: string; slug?: string; onUpdatePrompt: (improvedPrompt: string) => void }) => void;
  iterateOnPrompt: (userIterationText: string) => Promise<void>;
  onUpdatePrompt: ((improvedPrompt: string) => void) | null;
  generatePrompt: ({
    originalPrompt,
    slug,
    fieldName,
    userIterationText,
  }: { originalPrompt: string; slug: string; fieldName: string; userIterationText?: string }) => Promise<AssistantMessage>;
  close: () => void;
};

const defaultState = {
  promptAssistantDrawerOpen: false,
  slug: '',
  loading: false,
  fieldName: '',
  messages: [],
  loadingImprovements: false,
  onUpdatePrompt: null,
};

export const createPromptAssistantStoreSlice = createStoreSlice('PromptAssistantStoreData', defaultState, ({ set, get, setField }) => ({
  setField,
  load: async ({ fieldName, originalPrompt, slug, onUpdatePrompt }) => {
    if (!slug) return;

    const { generatePrompt } = get();
    set({ promptAssistantDrawerOpen: true, loading: true, fieldName, onUpdatePrompt });

    const newMessage = await generatePrompt({ originalPrompt, slug, fieldName });

    set({
      loading: false,
      slug,
      messages: [newMessage],
    });
  },
  iterateOnPrompt: async (userIterationText) => {
    const { slug, fieldName, messages, generatePrompt } = get();
    const promptMessage = (messages[messages.length - 1] as AssistantMessage)?.improvedPrompt;
    const updatedMessages: (UserMessage | AssistantMessage)[] = [...messages, { messageRole: 'user', userText: userIterationText }];
    set({ loadingImprovements: true, messages: updatedMessages });

    const newMessage = await generatePrompt({ originalPrompt: promptMessage, slug, fieldName, userIterationText });

    set({
      loadingImprovements: false,
      messages: [...updatedMessages, newMessage],
    });
  },
  generatePrompt: async ({ originalPrompt, slug, fieldName, userIterationText }) => {
    const response = await fetch('/api/generations/generatePromptAssistant', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ originalPrompt, slug, fieldName, userIterationText }),
    });

    const { improvedPrompt, promptImprovements }: GeneratePromptAssistantResponse = await response.json();

    return { messageRole: 'assistant', originalPrompt, improvedPrompt, promptImprovements };
  },
  close: () => {
    set(defaultState);
  },
}));
