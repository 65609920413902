import { type SetField, createStoreSlice } from 'features/store/zustand';
import { storage } from '../storage';
import type { Notification } from './types';

export type NotificationStore = {
  readNotifications: Notification['id'][];
  loading: boolean;
  magicStudentIntroModalOpen: boolean;
  customToolsIntroModalOpen: boolean;
  markAsRead: (id: Notification['id']) => void;
  load: () => void;
  setField: SetField<NotificationStore>;
};

const defaultState = {
  readNotifications: [],
  loading: true,
  magicStudentIntroModalOpen: false,
  customToolsIntroModalOpen: false,
};

export const createNotificationStoreSlice = createStoreSlice('NotificationStoreData', defaultState, ({ set, setField }) => ({
  setField,
  load: () => {
    const readNotifications: Notification['id'][] = JSON.parse(storage.getItem('read-notifications') || '[]');
    set({ readNotifications, loading: false });
  },
  markAsRead: (id) => {
    const readNotifications: Notification['id'][] = JSON.parse(storage.getItem('read-notifications') || '[]');
    const newNotifications: Notification['id'][] = [...new Set([...readNotifications, id])];
    storage.setItem('read-notifications', JSON.stringify(newNotifications));
    set({ readNotifications: newNotifications });
  },
}));
