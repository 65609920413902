import type { BaseConfig } from '../types';

const baseConfig: BaseConfig = {
  textExtract: {
    maxWordsPerDocument: 75_000,
    maxWordsPerGeneration: 75_000,
    maxCharactersPerDocument: 450_000,
    pdf: {
      languagesWithoutALotOfSpaces: ['ar', 'de', 'en-gb', 'en-us', 'es', 'fr', 'id', 'it'],
      otherToSpaceRatioErrorThreshold: 3,
      textDidNotParseErrorCharThreshold: 40,
    },
    image: {
      textDidNotParseErrorCharThreshold: 20,
      validMimeTypes: ['bmp', 'jpeg', 'png', 'webp'],
    },
    word: {
      findBoundaryRegex: /boundary="([^"]*)"/,
      textContentTypeString: 'Content-Type: text/html;',
      stripUnneededContentRegex: /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g,
    },
  },
  imageGenerator: {
    validMimeTypes: ['bmp', 'jpeg', 'png', 'webp'],
  },
  profilePicture: {
    validMimeTypes: ['bmp', 'jpeg', 'png', 'webp'],
  },
  passwords: {
    isValidRegex: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/,
  },
};

export default baseConfig;
