const testTextList = [
  'Education is the most powerful weapon which you can use to change the world.',
  'It is not what is poured into a student that counts but what is planted.',
  'The beautiful thing about learning is that no one can take it away from you.',
  "You're making incredible progress, and the effort you're putting in is truly inspiring to those around you.",
  "Every great achievement starts with a single step, and you're walking confidently toward success.",
  'Believe in the power of your abilities; you have everything it takes to reach your goals.',
  'Your creativity and determination are making a positive impact—keep shining!',
  'I hope today shimmers for you!',
  'If someone based an Internet meme on you, it would have impeccable grammar.',
  "You're like a ray of sunshine on a really dreary day.",
  "You're better than a triple-scoop ice cream cone. With sprinkles.",
  "If you were a box of crayons, you'd be the giant name-brand one with the built-in sharpener.",
];

export const generateTestText = () => {
  const idx = Math.floor(Math.random() * testTextList.length);
  return testTextList[idx];
};
