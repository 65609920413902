import { type GetRoomByShareCodeResponse, type RoomPostRequest, createNewRoom, getRoomByShareCode, updateRoomShareLink } from '@/app/(teacher)/rooms/actions';
import { analyticsTrack } from '@/util/analytics';
import { logger } from '@magicschool/logger';
import type { Room, RoomSharingAccessType } from '@magicschool/supabase/types';
import { getStudentUrl } from '@magicschool/utils/nextjs/url';
import { broadcastRoomStateChange } from 'features/realtime/messages';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import toast from 'react-hot-toast';
import type { IntlShape } from 'react-intl';
import { defaultRoom } from './types';
export type RoomActionsStore = {
  loading: boolean;
  deleteRoomModalOpen: boolean;
  deletingRoomText: string;
  room: Room;
  joinInfoModalOpen: boolean;
  joinInfoUrl: string;
  confirmRemoveModalOpen: boolean;
  joinInfoModalStep: 'codes' | 'options' | 'room' | 'student';
  shareDrawerOpen: boolean;
  shareDrawerLinkLoading: boolean;
  copyRoomModalOpen: boolean;
  loadingRoomToCopy: boolean;
  copyRoomError: string;
  copyRoomGrade: string;
  copyRoomName: string;
  roomToCopy: GetRoomByShareCodeResponse | null;
  copyingRoom: boolean;
  role: string;
  loadRoom: (room: Room) => void;
  setField: SetField<RoomActionsStore>;
  openJoinInfoModal: (roomStudentId?: string) => void;
  updateRoom: (room: Room, onUpdate: (room: Room) => void, intl: IntlShape, broadcastStateChange?: boolean) => Promise<void>;
  deleteRoom: (onDelete: (room: Room) => void) => Promise<void>;
  openCopyRoomModal: (shareCode: string) => void;
  closeCopyRoomModal: (router: AppRouterInstance, pathname: string) => void;
  copyRoom: (intl: IntlShape, router: AppRouterInstance, pathname: string) => void;
  openShareDrawer: (room: Room) => void;
  updateShareLinkAccessType: (accessType: RoomSharingAccessType) => void;
};
const defaultState = {
  loading: true,
  room: {
    ...defaultRoom
  },
  joinInfoModalOpen: false,
  joinInfoUrl: '',
  deleteRoomModalOpen: false,
  deletingRoomText: '',
  confirmRemoveModalOpen: false,
  joinInfoModalStep: 'options' as const,
  shareDrawerOpen: false,
  shareDrawerLinkLoading: false,
  copyRoomModalOpen: false,
  loadingRoomToCopy: false,
  copyRoomError: '',
  copyRoomGrade: 'pre-k',
  copyRoomName: '',
  roomToCopy: null,
  copyingRoom: false,
  role: ''
};
export const buildStudentUrl = (requireLogin: boolean, joinCode: string | null, roomStudentId?: string) => {
  const sessionId = roomStudentId && !requireLogin ? roomStudentId : null;
  const path = requireLogin ? 'login' : 'join';
  const url = new URL(`/s/${path}`, getStudentUrl());
  if (sessionId) url.searchParams.set('sessionId', sessionId);
  if (joinCode) url.searchParams.set('joinCode', joinCode);
  return url.toString();
};
export const createRoomActionsStoreSlice = createStoreSlice('RoomActionsStoreData', defaultState, ({
  set,
  get,
  getFull,
  setField
}) => ({
  setField,
  loadRoom: (room: Room) => {
    set({
      room
    });
  },
  updateRoom: async (room, onUpdate, intl, broadcastStateChange = true) => {
    const response = await fetch<Room>(`/api/rooms/${room.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        state: room.state,
        name: room.name
      }),
      responseErrorHandlers: {
        badRequest: () => {
          toast.error(intl.formatMessage({
            id: 'room-update.error'
          }));
          return {
            shortCircuit: true
          };
        },
        unknown: () => {
          toast.error(intl.formatMessage({
            id: 'room-update.error'
          }));
          return {
            shortCircuit: true
          };
        }
      }
    });
    const updatedRoom = await response.json();
    set({
      room: updatedRoom
    });
    onUpdate(updatedRoom);
    if (broadcastStateChange) {
      broadcastRoomStateChange(room.id, room.state);
    }
  },
  deleteRoom: async onDelete => {
    const room = get().room;
    const response = await fetch(`/api/rooms/${room.id}`, {
      method: 'DELETE'
    });
    if (!response.ok) return;
    onDelete(room);
    broadcastRoomStateChange(room.id, 'archived');
    set({
      deleteRoomModalOpen: false,
      deletingRoomText: ''
    });
  },
  openCopyRoomModal: async shareCode => {
    set({
      copyRoomModalOpen: true,
      loadingRoomToCopy: true,
      copyRoomError: defaultState.copyRoomError,
      copyRoomGrade: defaultState.copyRoomGrade,
      copyRoomName: defaultState.copyRoomName,
      copyingRoom: defaultState.copyingRoom
    });
    const results = await getRoomByShareCode(shareCode);
    if (results.error) {
      logger.error(results.error.message, {
        error: results.error
      });
      let errorMessage = 'something-went-wrong';
      if (results.error.message === 'Unauthorized') {
        errorMessage = 'add-room-template.error.unauthorized';
      } else if (results.error.message === 'Room not found') {
        errorMessage = 'add-room-template.error.not-found';
      }
      set({
        loadingRoomToCopy: false,
        copyRoomError: errorMessage
      });
      return;
    }
    analyticsTrack('StudentRooms:CopyRoomModal', {
      shareCode
    });
    set({
      roomToCopy: results.data,
      copyRoomGrade: results.data?.gradeLevel,
      copyRoomName: results.data?.name,
      loadingRoomToCopy: false
    });
  },
  closeCopyRoomModal: (router, pathname) => {
    router.replace(pathname);
    set({
      copyRoomModalOpen: false
    });
  },
  copyRoom: async (intl, router, pathname) => {
    const {
      roomToCopy,
      copyRoomGrade,
      copyRoomName,
      closeCopyRoomModal
    } = get();
    if (!roomToCopy) return;
    set({
      copyingRoom: true
    });
    const roomData: RoomPostRequest = {
      name: copyRoomName,
      gradeLevel: copyRoomGrade,
      maxStudents: roomToCopy.maxStudents,
      requireLogin: roomToCopy.requireLogin,
      tools: roomToCopy.tools,
      parentRoomId: roomToCopy.id
    };
    const res = await createNewRoom(roomData);
    if (res.data) {
      getFull().RoomListStoreData.addRoom(res.data);
      toast.success(intl.formatMessage({
        id: 'add-room-template.success'
      }), {
        duration: 4000
      });
      analyticsTrack('StudentRooms:CopyRoom', {
        oldRoom: roomToCopy.id,
        newRoom: res.data.room.id
      });
    } else if (res.error) {
      logger.error(res.error.message, {
        error: res.error
      });
      set({
        copyRoomModalOpen: false
      });
      toast.error(intl.formatMessage({
        id: 'add-room-template.error'
      }), {
        duration: 4000
      });
    }
    closeCopyRoomModal(router, pathname);
  },
  openJoinInfoModal: roomStudentId => {
    const {
      room
    } = get();
    set({
      joinInfoModalOpen: true,
      joinInfoModalStep: roomStudentId ? 'student' : 'options',
      joinInfoUrl: buildStudentUrl(room.require_login, room.join_code, roomStudentId)
    });
  },
  openShareDrawer: room => {
    set({
      shareDrawerOpen: true,
      room: room
    });
  },
  updateShareLinkAccessType: async accessType => {
    set({
      shareDrawerLinkLoading: true
    });
    const {
      room
    } = get();
    analyticsTrack('StudentRooms:UpdateShareLinkAccessType', {
      location: 'RoomsPage',
      accessType
    });
    const result = await updateRoomShareLink(room.id, accessType);
    if (result.error) {
      logger.error(result.error.message, {
        error: result.error
      });
      toast.error('Failed to update sharing link');
      set({
        shareDrawerLinkLoading: false
      });
    }
    if (!result.data) return;
    set({
      shareDrawerLinkLoading: false,
      room: result.data
    });
  }
}));