import MuiChip, { type ChipProps as MuiChipProps } from '@mui/material/Chip';
import { forwardRef } from 'react';
export interface ChipProps extends MuiChipProps {
  'aria-label'?: string;
  'data-test-id'?: string;
  ref?: React.Ref<HTMLDivElement>;
}
export const Chip = forwardRef<HTMLDivElement, ChipProps>(({
  ...rest
}, ref) => {
  return <MuiChip ref={ref} {...rest} />;
});